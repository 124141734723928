import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';
import GatedContentForm from '../molecules/GatedContentForm';

interface GuideHeroProps {
  title: string;
  date: string;
  contentDownload: string;
  downloadFileUrl: string;
}

const GuideHero: React.FC<GuideHeroProps> = ({
  title,
  date,
  downloadFileUrl,
  image,
  content,
  contentDownload,
}) => {
  const {
    wp: {
      options: {
        globalOptions: {
          resourceLabels,
          downloadReadyBody,
          downloadReadyLinkText,
        },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            resourceLabels {
              guide
            }
            downloadReadyBody
            downloadReadyLinkText
          }
        }
      }
    }
  `);

  const downloadReadyParts = downloadReadyBody
    .replace('{title}', title)
    .split('{link}');
  const downloadReadyComponent = [
    downloadReadyParts[0],
    <a
      href={downloadFileUrl}
      target="_blank"
      className="text-primary-red"
      key={1}
    >
      {downloadReadyLinkText}
    </a>,
    downloadReadyParts.slice(1),
  ];

  return (
    <section className="py-16 bg-tertiary lg:py-20">
      <div className="space-y-8 u-container lg:flex lg:space-x-16 lg:space-y-0">
        <div className="pt-8 lg:w-1/2">
          <div className="flex mb-12">
            <CtaLink
              to="/guides/"
              label="Back to Guides &amp; Reports"
              reversed
            />
          </div>
          <div className="inline-flex items-center justify-center space-x-5 bg-white flex-2 shadow-glassdoor py-3.5 px-6 font-semibold rounded-lg mx-auto mb-9">
            <div>{resourceLabels.guide}</div>
            <div className="text-primary-red">
              {moment(date).format('DD MMMM')}
            </div>
          </div>

          <h1 className="u-h3" dangerouslySetInnerHTML={{ __html: title }} />
          {image && <Image className="mt-8" data={image} />}
          {content && (
            <div
              className="mt-8 prose"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        <div className="lg:w-1/2">
          <GatedContentForm
            url="https://go.spirable.com/l/843243/2021-02-26/mpywff"
            mergeFields={{ content_download: contentDownload }}
            submitLabel="Download Guide"
            successTitle="Got it!"
            successBody={<>{downloadReadyComponent}</>}
          />
        </div>
      </div>
    </section>
  );
};

export default GuideHero;
