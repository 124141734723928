import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import DynamicContent from '../components/organisms/DynamicContent';
import GuideHero from '../components/organisms/GuideHero';
import ResourceRelatedArticlesSection from '../components/organisms/ResourceRelatedArticlesSection';

const EventPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo post={page} />

      <GuideHero
        title={page.title}
        date={page.date}
        contentDownload={page?.guide?.contentDownload}
        downloadFileUrl={page?.guide?.downloadFile?.mediaItemUrl}
        image={page?.guide?.guideHeroImage}
        content={page?.guide?.guideHeroContent}
      />
      <DynamicContent blocks={page.guide.content} />
      {page.guide.relatedArticles && (
        <ResourceRelatedArticlesSection
          title="Related Articles"
          viewAllUrl="/guides"
          resources={page.guide.relatedArticles}
        />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query GuideQuery($id: String!) {
    page: wpGuide(id: { eq: $id }) {
      ...GuideSEO
      date
      title
      nodeType
      slug
      featuredImage {
        node {
          ...Image
        }
      }
      guide {
        guideHeroContent
        guideHeroImage {
          ...Image
        }
        contentDownload
        downloadFile {
          mediaItemUrl
        }
        content {
          ... on WpGuide_Guide_Content_Wysiwyg {
            content
            fieldGroupName
          }
          ... on WpGuide_Guide_Content_FullWidthImage {
            description
            fieldGroupName
            image {
              ...Image
            }
          }
          ... on WpGuide_Guide_Content_SplitTextAndImage {
            content
            fieldGroupName
            imageDescription
            image {
              ...Image
            }
          }
          ... on WpGuide_Guide_Content_FullWidthQuote {
            fieldGroupName
            quote
            author {
              name
              title
            }
          }
        }
        #   relatedArticles {
        #     ... on WpPost {
        #       title
        #       slug
        #       nodeType
        #       dateGmt
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }

        #     ... on WpGuide {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }
        #     ... on WpNewsItem {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #     }
        #     ... on WpEvent {
        #       title
        #       slug
        #       nodeType
        #       date
        #       featuredImage {
        #         node {
        #           ...Image
        #         }
        #       }
        #       event {
        #         eventDate
        #       }
        #     }
        #   }
      }
    }
  }
`;

export default EventPage;
